/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react'
import React, { Suspense, lazy } from 'react' // eslint-disable-line
import { Router } from '@reach/router'
// Assets
import { ReactComponent as PBPbanner } from './svg/poweredbypig-confondo.svg'
import Preloader from './components/Preloader'
// Sections
const Hero = lazy(() =>
   import('./components/Sections/Hero')
)
const About = lazy(() =>
   import('./components/Sections/About')
)
const Classes = lazy(() =>
   import('./components/Sections/Classes')
)
const Plans = lazy(() =>
   import('./components/Sections/Plans')
)
const Coaches = lazy(() =>
   import('./components/Sections/Coaches')
)
const Horario = lazy(() =>
   import('./components/Sections/Horario')
)
const Contacto = lazy(() =>
   import('./components/Sections/Contacto')
)
const Footer = lazy(() =>
   import('./components/Sections/Footer')
)
// Utilities
const Navigation = lazy(() =>
   import(
      './components/Navigation/Navigation'
   )
)
const MotivMessage = lazy(() =>
   import(
      './components/Utilities/MotivMessage'
   )
)
const BrandBanner = lazy(() =>
   import(
      './components/Utilities/BrandBanner'
   )
)
const ContactSuccess = lazy(() =>
   import(
      './components/Utilities/ContactSuccess'
   )
)

function SinglePageApplication() {
   return (
      <React.Fragment>
         <Suspense fallback={<Preloader />}>
            {/* Fixed navigation */}
            <Navigation />
            <Hero />
            {/* Powered by pig banner */}
            <BrandBanner
               BrandComponent={PBPbanner}
            />
            {/* About Section */}
            <About />
            {/* Motiv Message: Stop wishing, start desiring */}

            <MotivMessage
               color='var(--lightColor)'
               background='var(--darkColor)'
            >
               Train your body
               <span
                  css={css`
                     @media (max-width: 980px) {
                        display: none;
                     }
                  `}
               >
                  ,
               </span>
               <br
                  css={css`
                     @media (min-width: 980px) {
                        display: none;
                     }
                  `}
               />
               train your mind
               <span
                  css={css`
                     @media (max-width: 980px) {
                        display: none;
                     }
                  `}
               >
                  .
               </span>
            </MotivMessage>
            {/* classes section */}
            <Classes />
            {/* Motiv Message: Stop wishing, start desiring */}
            <MotivMessage
               color='var(--lightColor)'
               background='var(--darkColor)'
            >
               Stop thinking
               <span
                  css={css`
                     @media (max-width: 980px) {
                        display: none;
                     }
                  `}
               >
                  ,
               </span>
               <br
                  css={css`
                     @media (min-width: 980px) {
                        display: none;
                     }
                  `}
               />
               start doing
               <span
                  css={css`
                     @media (max-width: 980px) {
                        display: none;
                     }
                  `}
               >
                  .
               </span>
            </MotivMessage>
            {/* Tarifas y planes */}
            <Plans />
            {/* Motiv Message: Stop wishing, start desiring */}
            <MotivMessage
               color='var(--lightColor)'
               background='var(--darkColor)'
            >
               Go Speck
               <span
                  css={css`
                     @media (max-width: 980px) {
                        display: none;
                     }
                  `}
               >
                  ,
               </span>
               <br
                  css={css`
                     @media (min-width: 980px) {
                        display: none;
                     }
                  `}
               />
               Go all in
               <span
                  css={css`
                     @media (max-width: 980px) {
                        display: none;
                     }
                  `}
               >
                  .
               </span>
            </MotivMessage>
            {/* Coaches */}
            <Coaches />
            {/* Horario */}
            <Horario />
            {/* Motiv Message: Stop wishing, start desiring */}
            <MotivMessage
               color='var(--lightColor)'
               background='var(--darkColor)'
            >
               #SPECKRULES
            </MotivMessage>
            {/* Contact */}
            <Contacto />
            {/* Footer */}
            <Footer />
         </Suspense>
      </React.Fragment>
   )
}

function App() {
   return (
      <React.Fragment>
         <Suspense fallback='loading...'>
            <Router>
               <ContactSuccess path='/contact-success' />
               <SinglePageApplication path='/' />
            </Router>
         </Suspense>
      </React.Fragment>
   )
}

export default App
