/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react'
import { ReactComponent as PreloaderLogo } from '../svg/preloader.svg'
import hourglass from '../animations/hourglass'

export default function Preloader() {
   return (
      <div
         css={css`
            min-height: 100vh;
            background-color: var(
               --darkColor
            );
         `}
      >
         <div
            css={css`
               background-color: var(
                  --darkColor
               );
               display: flex;
               flex-direction: row;
               max-width: 980px;
               margin: 0 auto;

               min-height: 100vh;
               align-items: center;
               justify-content: center;
            `}
         >
            <PreloaderLogo
               css={css`
                  /* animation: ${hourglass} 2.4s
                     cubic-bezier(
                        0,
                        0.2,
                        0.8,
                        1
                     )
                     infinite; */
                  animation: ${hourglass}
                     1.2s infinite;

                  height: 21rem;
               `}
            />
         </div>
      </div>
   )
}
